@import "../../../styles/global.scss";

.wrapper {
  display: flex;
  justify-content: center;
  background-image: url(../../../assets/images/services/global_dots.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  @include respond(medium) {
    background-image: none;
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding-bottom: 30px;
  }

  .container_left {
    img {
      @include respond(small) {
        width: 90%;
      }
    }
  }
  .container_right {
    flex-basis: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    @include respond(medium) {
      margin-top: 12px;
    }
    @include respond(small) {
      margin-top: 10px;
    }
    // border: 1px solid red;
    @include respond(medium) {
      // flex-direction: row;
    }
    .icon_wrapper {
      width: 80px;
      height: 80px;
      background-color: #ffe3df;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid blue;
    }
    h1 {
      margin: 29px 75px 36px 75px;
      font-family: Poppins;
      font-size: 28px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
    }
    .bar {
      width: 71px;
      height: 3px;
      background-color: #ff561d;
      margin-bottom: 36px;
    }
    h3 {
      font-family: Montserrat;
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #0c71c3;
    }
    p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      margin: 20px 75px 50px 75px;
    }
    .btn-con {
      display: flex;
      align-items: center;

      .btn {
        all: unset;
        background: linear-gradient(
          5.86deg,
          #a72bfa -53.1%,
          #ef1380 -10.33%,
          #ff3838 67.81%
        );
        color: #fff;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        padding: 1rem 1.8rem;
        border: 3px solid transparent;
        border-radius: 5px;
        &:hover {
          background: #fff;
          border: 3px solid #0074d1;
          color: #0074d1;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
        @include respond(medium) {
          font-weight: 500;
          font-size: 0.8;
          padding: 0.8rem 1.5rem;
        }
        @include respond(small) {
          font-weight: 500;
          font-size: 0.6;
          padding: 0.8rem 1.5rem;
        }
      }
    }
  }
}
