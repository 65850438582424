@import "../../../styles/global.scss";

.section_top{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    width: 80%;
    margin: auto;
    // border: 1px solid red;
    @include respond (medium){
        flex-direction: column;
        gap: 10px;
    }
    @include respond (small){
        flex-direction: column;
    }

    .service_image{
        @include respond (small){
            width: 100%;
        }
    }
    .top_right{
        margin-left: 77px;
        @include respond(medium){
            margin:auto;
        }
        .heading_wrapper{
            h1{
                font-family: Poppins;
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                letter-spacing: 0em;
                text-align: left;
                color: #363636;  
            }
        }
        p{
            margin-top:25px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #444445;
        }
        .web_services{
            margin-top:40px ;
            display: flex;
            justify-content: space-between;
            .bullet{
                margin-right: 15px;
                margin-top: 6px;
                // border: 1px solid red;
            }
            .contain{
                // border: 1px solid red;
                // display: flex;
                
                h5{
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 32px;
                    text-align: left;
                    color: #363636; 
                }
                p{
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #444445;
                    margin-top: 16px !important;
                }
            }
        }
    }
}