.dashboard_layout {
  max-height: 100vh;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .dash_sidebar {
    // position: fixed;
    // top: 0;
    // left: 0;
    background-color: #fff;
    height: 100vh;
    width: 234px;
    border-right: 0.5px solid #e2e2e2;
    padding: 25px;
    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        height: 40px;
      }
      margin-bottom: 45px;
    }
    .dashlink_container {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .dashlink {
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #7b7b7b;
          .icon {
            height: 14px;
            width: 14px;
            // background-color: rgb(233, 233, 233);
            display: grid;
            place-items: center;
          }
          &:hover {
            color: #000000;
          }
        }
        .active-dash-link {
          color: #000000;
        }
      }
    }
  }
  .dash_right {
    background-color: rgb(235, 235, 235);
    padding: 0 25px;
    flex-grow: 1;
    max-height: 100vh;
    overflow-y: scroll;
    .dr_top {
      height: 70px;
      // background-color: rgb(180, 114, 28);
    }
    .dr_bottom {
      .dashbody {
        background-color: rgb(255, 255, 255);
        height: 100vh;
        border-radius: 12px 12px;
        padding: 32px 46px;
        .dashbody-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 30px;
        }
      }
    }
  }
}
