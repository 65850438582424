@import "../../styles/global.scss";

.footer_wrapper {
  background: linear-gradient(
      108.01deg,
      #279def 3.61%,
      #1e8ee0 8.55%,
      #0c71c3 37.97%
    ),
    linear-gradient(268.49deg, #0c71c3 9.81%, #2ea3f2 91.82%);
  color: #fff;

  background-color: red;

  .footer-section {
    margin: 0 10%;
    border: 1px solid transparent;
    @include respond(smalllaptop) {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.first_section {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: 5% 0;
  gap: 15px;

  @include respond(medium) {
    grid-template-columns: 1fr;
  }
  @include respond(small) {
    grid-template-columns: 1fr;
  }

  h1 {
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    color: #ffffff;
    @media screen and (max-width: 800px) {
      padding-top: 15px;
      font-size: 25px;
      line-height: 35px;
    }
  }

  .btn_wrapper {
    // border: 1px solid red;
    display: flex;
    justify-content: flex-end;
    @include respond(medium) {
      justify-content: flex-start;
      margin-top: 1rem;
    }
    @include respond(small) {
      justify-content: flex-start;
      margin-top: 1rem;
    }
  }

  .footer-contact {
    display: flex;
    align-items: center;

    .contact-button {
      all: unset;
      background: linear-gradient(
        5.86deg,
        #a72bfa -53.1%,
        #ef1380 -10.33%,
        #ff3838 67.81%
      );
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      text-transform: capitalize;
      padding: 1rem 1.8rem;
      // border: 1px solid #ffffff;
      border-radius: 5px;
      &:hover {
        background: #fff;
        color: #0074d1;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
      @include respond(medium) {
        font-weight: 500;
        font-size: 0.8;
        padding: 0.8rem 1.5rem;
      }
      @include respond(small) {
        font-weight: 500;
        font-size: 0.6;
        padding: 0.8rem 1.5rem;
      }
    }
  }
}

.divder {
  margin-right: 15%;
  opacity: 0.2;
}

.second_section {
  //   display: grid;
  //   grid-template-columns: 1fr 3fr;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  flex-wrap: wrap;
  margin: 5% 0;

  @include respond(smalllaptop) {
    // border: 1px solid red;
    width: 100%;
  }

  @include respond(medium) {
    grid-template-columns: 2fr;
  }
  @include respond(small) {
    grid-template-columns: 1fr;
  }

  .social_links {
    // border: 1px solid red;
    width: 250px;
    // @media screen and (max-width: 1200px) {
    //   width: 100%;
    // }

    @include respond(smalllaptop) {
      width: 350px;
    }
    .logo_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 13px;
      // row-gap: 3rem;
      // background-color: red;

      .logotitlecv {
        // margin-left: 12px;
        // font-style: normal;
        // font-weight: 700;
        font-size: 1.7rem;
        // line-height: 33px;
        // /* identical to box height */

        color: #dfebff;
      }
    }

    .footer_about {
      margin-top: 5%;
      margin-right: 5%;
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6rem;
    }

    .social_link_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include respond(smalllaptop) {
        justify-content: flex-start;
      }

      @include respond(medium) {
        justify-content: space-around;
      }
      @include respond(small) {
        justify-content: space-between;
      }

      svg {
        margin: 15px;
        &:hover {
          animation: pulse 1s infinite;
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          70% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    // border: 1px solid green;
    gap: 30px;

    // padding-left: 1rem;

    @include respond(medium) {
      justify-content: space-between;
      flex-wrap: wrap;
      // border: 1px solid red;
    }
    @include respond(small) {
      flex-direction: column;
      flex-wrap: nowrap;
      // margin-top: 1rem;
    }

    & h2 {
      @include respond(medium) {
        font-size: 1.6rem;
        margin-top: 2rem;
      }
      @include respond(small) {
        // border: 1px solid red;
        font-size: 1.4rem;
      }
    }

    .f_links {
      list-style: none;
      margin-top: 13px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #eeeeee;

      // border: 1px solid red;
      @include respond(medium) {
        margin-top: 1rem;
        // border: 1px solid blue;
      }
      @include respond(small) {
        // font-size: 1.4rem;
      }
    }
  }

  .sub {
    // border: 1px solid green;
    @include respond(medium) {
      flex-basis: 50%;
    }
    @include respond(small) {
      margin-top: 1.5rem;
      // border: 1px solid pink;
    }
  }

  .f_address {
    .f_links {
      margin-top: 15px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #eeeeee;

      @include respond(small) {
        margin-top: 10px;
      }
    }
  }
}

.third_section {
  margin: 3% auto 3% auto;
  display: flex;
  justify-content: center;

  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6rem;

    span {
      font-size: 0.98rem;
      font-weight: 500;
      line-height: 1.6px;
    }
  }
}
