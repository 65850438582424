@import "../../styles/global.scss";

.dummy-container {
  height: 73px;
}

.landing-cover-container {
  padding: 8rem 0;
  background-image: url(../../assets/images/coverbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include respond(small) {
    padding-bottom: 2rem;
    // height: 85vh;
    display: flex;
  }

  .landing-cover-image {
    width: 40%;
    @media screen and (max-width: 690px) {
      display: none;
    }

    img {
      max-width: 100%;
    }
  }

  .landing-cover-main {
    width: 75%;
    margin: 0 auto 1rem auto;
    display: flex;
    justify-content: space-between;

    @include respond(smalllaptop) {
      width: 90%;
    }

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }

    .landing-cover-info {
      width: 40%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // gap: 1rem;

      justify-content: center;
      color: #dfebff;

      h1 {
        font-size: 48px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 68px;
        margin: 0;
        margin-bottom: 26px;
        // font-family: "Mazzard";

        @include respond(medium) {
          font-size: 2rem;
          font-weight: 600;
        }

        @include respond(small) {
          font-size: 25px;
          // background-color: red;
          width: 100%;
          line-height: 45px;
          margin-bottom: 13px;
        }
      }

      p {
        margin: 0;
        font-size: 1.25rem;
        margin-bottom: 38px;

        @include respond(medium) {
          font-size: 1rem;
        }
        @include respond(small) {
          font-size: 15px;
          // background-color: red;
          width: 100%;
        }
      }

      .gradient-button {
        all: unset;
        padding: 22px 30px;
        background: linear-gradient(
          5.86deg,
          #a72bfa -53.1%,
          #ef1380 -10.33%,
          #ff3838 67.81%
        );
        border-radius: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        @include respond(small) {
          font-size: 13px;
          padding: 11px 13px;
        }

        // @include respond(medium) {
        //   font-size: 1rem;
        //   font-weight: 600;
        //   padding: 0.6rem 1.8rem;
        // }

        &:hover {
          background: #fff;
          color: #0074d1;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 0;
  @include respond(medium) {
    flex-wrap: wrap;
  }

  .services-heading {
    h3 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #0c71c3;

      @include respond(small) {
        font-size: 2rem;
      }
    }
    h2 {
      margin-top: 20px;
      text-align: center;
      font-size: 32px;
      line-height: 39.1px;
      font-weight: 600;
      font-family: "Montserrat";
      @include respond(small) {
        font-size: 2rem;
      }
    }
  }
  .services_pre_contains {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    gap: 60px;
    @include respond(medium) {
      gap: 25px;
      margin-bottom: 20px;
    }
    .pre_contains {
      // border: 1px solid blue;
      margin: 0 auto;
      width: 363px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      svg {
        // border: 1px solid red;
        width: 20px;
        height: 15px;
        margin-right: 10px;
        margin-top: 8px;
        flex-basis: 8%;
      }
      .list_container {
        flex-basis: 92%;
        h3 {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          color: #2ea3f2;
        }
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          color: #444445;
        }
      }
    }
  }

  .services-container {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 0 auto;
    justify-content: center;
    gap: 2.5rem;
    // border: 1px solid red;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
      flex-direction: column;
    }
  }
}

.projects-wrapper {
  background: linear-gradient(268.49deg, #0c71c3 9.81%, #2ea3f2 91.82%);
  padding: 4rem 0 7rem 0;

  .projects-container {
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }

    .project:nth-child(2n) {
      position: relative;
      top: 3rem;

      @include respond(medium) {
        position: inherit;
        top: 0;
      }
    }
  }
}

.works-wrapper {
  padding: 4rem 0;
  text-align: center;

  .works-main {
    @include respond(small) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .works-heading {
    display: flex;
    flex-direction: column;
    gap: 14px;

    h3 {
      color: #0c71c3;
      font-size: 1.25rem;
      font-weight: 600;
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 1;

      @include respond(small) {
        font-size: 2rem;
      }
    }

    p {
      color: rgba(54, 54, 54, 0.996093);
      font-size: 1.25rem;
    }
  }

  .works-container {
    margin: 0 auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }

    .work:nth-child(2n) {
      margin-left: -2rem;

      @include respond(medium) {
        margin-left: 0;
      }

      .work-image {
        order: 3;
      }

      .work-divider {
        order: 2;
        align-items: flex-start;
      }

      .work-separator {
        order: 1;
      }

      .work-content {
        order: 0;
      }
    }
  }
}

.team-wrapper {
  @include respond (small){
    padding-bottom: 1rem;
  }
  .team-heading {
    margin-bottom: 2rem;

    h2 {
      text-align: center;
      font-size: 3rem;
      font-weight: 500;

      @include respond(small) {
        font-size: 2rem;
      }
    }
  }

  .team-container {
    padding: 0 0 4rem 0;
    @include respond(small){
      // background-color: red;
      padding: 0px;

    }
  }
}

.starting-wrapper {
  background-image: url(../../assets/images/startingproject.png);
  background-size: 100% 100%;
  padding: 6rem 0;
  margin: 4rem 0;

  @include respond(small) {
    padding: 3rem 0;
  }

  .starting-container {
    position: relative;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }

    &-rocket {
      position: absolute;
      top: -150px;
      left: 0;
      width: 185px;
      height: 313px;

      @include respond(medium) {
        display: none;
      }

      img {
        max-width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-right {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(small) {
        flex-direction: column;
        gap: 2rem;
      }
    }

    &-heading {
      h2 {
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
    }

    &-button {
      button {
        all: unset;
        padding: 0.6rem 1.8rem;
        background: linear-gradient(
          5.86deg,
          #a72bfa -53.1%,
          #ef1380 -10.33%,
          #ff3838 67.81%
        );
        border-radius: 8px;
        font-size: 1.125rem;
        font-weight: 600;
        color: #fff;
        cursor: pointer;

        &:hover {
          background: #fff;
          color: #0074d1;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.testimonials-wrapper {
  padding: 4rem 0;
  background-image: url(../../assets/images/testibg.svg);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: -30%;
  // position: absolute;
  margin-bottom: 5rem;

  .testimonials_head {
    font-size: 3rem;
    font-weight: 600;
    color: #444444;
    text-align: center;
    margin-bottom: 2%;

    span {
      background-image: linear-gradient(
        5.86deg,
        #a72bfa -53.1%,
        #ef1380 -10.33%,
        #ff3838 67.81%
      );
      background-clip: text;
      color: transparent;
      border-bottom: 2px solid #ef1380;
    }
  }

  .testimonials-container {
    width: 55%;
    margin: 0 auto;
    position: relative;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }
  }
}
