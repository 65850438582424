@import "../../../styles/global.scss";

.usection_top {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  @include respond(medium) {
    flex-direction: column;
  }
  @include respond(small) {
    flex-direction: column;
  }
  //   border: 1px solid red;
  .usection_left {
    flex-basis: 40%;
    img {
      @include respond(medium) {
        width: 90%;
      }
      @include respond(small) {
        width: 90%;
      }
    }
  }
  .usection_right {
    flex-basis: 60%;
    padding-left: 5%;
    @include respond(small) {
      padding: auto;
    }
    h1 {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 25px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .icon {
        margin: 36px 16px;
        // border: 1px solid red;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 100px;
        text-transform: capitalize;
        @include respond(small) {
          margin-right: auto;
        }
      }
    }
  }
}

.ucard_wrapper {
  margin-left: 10%;
  margin-bottom: 5%;
  width: 80%;
  @include respond(medium) {
    // margin-left: 5%;
    margin: 5%;
    width: 100%;
  }
  @include respond(small) {
    // margin: auto;
  }

  h2 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  &-p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 545px;
    @include respond(medium) {
      width: 90%;
    }
  }
}

.ucard_main_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @include respond(medium) {
    flex-wrap: wrap;
    padding: auto;
    justify-content: center;
    width: 90%;
    margin-top: 20px;
    justify-content: space-around;
  }
}

.ucard_container {
  width: 544px;
  border: 1px solid #363636;
  border-radius: 8px;
  margin: 10px 20px 15px 0px;
  @include respond(medium) {
    width: 75%;
  }

  .image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .ucard_contain {
    padding: 40px 80px 80px 60px;
    @include respond(medium) {
      padding: 10px;
    }
    @include respond(small) {
      padding: 8px;
    }
    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: #363636;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #444445;
    }
  }
}
.ucard_min_wrapper {
  display: flex;
  justify-content: space-between;
  // border: 1px soild red;
  @include respond(medium) {
    flex-wrap: wrap;
    padding: auto;
    justify-content: center;
    width: 90%;
    margin-top: 20px;
    justify-content: space-around;
  }
}

.ucard_mini_container {
  width: 353px;
  border-radius: 8px;
  border: 1px solid #444445;
  margin: 10px 15px 20px 0px;
  @include respond(medium) {
    width: 75%;
  }
  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      @include respond(medium) {
        width: 100%;
      }
    }
  }
  .ucard_contain {
    margin: 45px 50px 60px 50px;
    @include respond(medium) {
      margin: 10px;
    }
    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: #363636;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #444445;
    }
  }
}

.belt-wrapper {
  background-image: url(../../../assets/images/startingproject.png);
  background-size: 100% 100%;
  padding: 4rem 0;
  margin: 4rem 0;

  @include respond(small) {
    padding: 3rem 0;
  }

  .belt-container {
    position: relative;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include respond(medium) {
      width: 85%;
    }

    @include respond(small) {
      width: 90%;
    }

    &-right {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @include respond(small) {
        flex-direction: column;
        gap: 2rem;
      }
    }

    &-heading {
      margin-bottom: 30px;
      h2 {
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
    }

    &-button {
      button {
        all: unset;
        padding: 0.6rem 1.8rem;
        background: linear-gradient(
          5.86deg,
          #a72bfa -53.1%,
          #ef1380 -10.33%,
          #ff3838 67.81%
        );
        border-radius: 8px;
        font-size: 1.125rem;
        font-weight: 600;
        color: #fff;
        cursor: pointer;

        &:hover {
          background: #fff;
          color: #0074d1;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
.solution-image-container {
  margin: 60px 160px 100px 160px;
  @include respond (medium){
    margin: 30px 30px 50px 30px;

  }
  img{
    @include respond(medium){
      width: 100%;
    }
  }
}

// style of the ladt section of the page

.solution-wrapper {
  // border: 1px solid red;
  width: 70%;
  margin: 165px auto auto auto;
  @include respond (medium){
    width: 90%;
    margin: auto;
  }
  .solution-container {
    width: 50%;
    margin-bottom: 50px;

    @include respond(medium) {
      width: 100%;
    }
    &-headwrapper {
      margin-bottom: 20px;
    }
    &-heading {
      font-family: Poppins;
      font-style: normal;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      color: #363636;
    }

    &-para {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      @include respond(medium) {
        font-size: 14px;
      }
    }
  }
  .solution-card-wrapper {
    // border: 1px solid green;
    display: flex;
    flex-wrap: wrap;
  }
}

// style of the card useed in this section
.scard-container {
  width: 400px;
  display: flex;
  // border: 1px solid blue;
  margin: 0px 80px 60px 0px;
  @include respond(medium){
    // border: 1px solid red;
    width: 80%;
    gap:20px;
  }
  @include respond(small) {
    margin: auto;
  }
  &-right {
    margin-right: 15px;
    @include respond(small) {
      margin-right: 0%;
    }
  }
  
  &-heading {
    padding: 11px 0px 11px 0px;
    @include respond(small) {
      // padding: 0px;
      // border: 1px solid red;
    }
    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      @include respond(small) {
        min-width: 100px;
      }
    }
  }
  &-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
