@import '../../styles/global.scss';


.services_wrapper{
    margin: auto;
    // border: 1px solid red;
.services_head_wrapper{
    padding-top: 136px;
    width: 85%;
    margin: auto;
    @include respond(medium){
        padding-top:100px;
        width: 90%;
    }
    @include respond(small){
       width: 100%; 
    }
    h4{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #0C71C3;
    }
    h2{
        font-family: Poppins;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color:#363636;
        text-transform: capitalize;
        margin-top: 15px;
        @include respond(medium){
            margin-top: 12px;
        }
        @include respond(small){
            margin-top: 10px;
        }
    }
    p{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #444445;
        margin-top: 30px;
        @include respond(medium){
            margin-top: 12px;
        }
        @include respond(small){
            margin-top: 10px;
        }

    }
}
}