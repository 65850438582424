@import '../../styles/global.scss';
.work {
    position: relative;
    display: flex;
    gap: 0;
    align-items: center;
    justify-content: space-between;
    &-image {
        width: 45%;
        @include respond(small) {
            display: none;
        }
    }
    &-divider {
        width: 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        box-sizing: content-box;
        @include respond(medium) {
            display: none;
        }
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        input {
            width: 1rem;
            aspect-ratio: 1;
        }
        &-top,
        &-bottom {
            // width: 100%;
            box-sizing: content-box;
            height: 150px;
            border: 1px dashed #B4B0B0;
        }

        &-void {
            width: 7px;
        }
    }

    &-separator {
        width: 5%;
        border: 1px dashed #B4B0B0;
        @include respond(medium) {
            display: none;
        }
    }

    &-content {
        width: 45%;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
        background: rgba(255, 255, 255, 0.954414);
        box-shadow: 2px 5px 20px rgba(23, 22, 139, 0.0763531);
        border-left: 5px solid;
        @include respond(medium) {
            width: 50%;
        }
        @include respond(small) {
            width: 100%;
        }
        &-index {
            h2{
                width: 64px;
                aspect-ratio: 1;
                background-color: blue;
                border-radius: 50%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                font-weight: 500;
            }
        }
        &-desc {
            flex: 1;
            text-align: left;
            h2 {
                font-size: 1.25rem;
                font-weight: 600;
            }
            p {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}