@import "../../styles/global.scss";

@keyframes slide-nav-item {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.hashlink{
  text-decoration: none!important;
}
@mixin navItemAnimation($delay) {
  animation: slide-nav-item 0.3s linear both;
  animation-delay: $delay;
}

.navbar-container {
  background-image: linear-gradient(
    278.81deg,
    #1a5bc3 17.83%,
    #0074d1 49.55%,
    #1c9df3 91.98%
  );
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  padding: 0.6rem 0;

  .navbar-wrapper {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    @include respond(smalllaptop){
      width: 90%;
    }

    @include respond(medium) {
      width: 85%;
    }
    @include respond(small) {
      width: 95%;
    }
    .navbar-logo{
      // background-color: red;
      .logoimgcv{
        width:44px;
        height:44px;
      }
      .logotitlecv{
        color: #dfebff;
        // color: red;
      }
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      color: #fff;
      background-color: #0074d1;
      height: 100vh;
      width: 100vw;
      padding: 10rem 0;
      background-image: radial-gradient(
        rgba(255, 255, 255, 0.3) 9%,
        transparent 9%
      );
      background-position: 0% 0%;
      background-size: 12vmin 12vmin;
    }

    .navbar-menu-small {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
      margin: 0 auto;
      list-style-type: none;

      li {
        font-size: 2rem;
        font-weight: 600;
        user-select: none;
        &:nth-child(1) {
          @include navItemAnimation(0.3s);
        }
        &:nth-child(2) {
          @include navItemAnimation(0.4s);
        }
        &:nth-child(3) {
          @include navItemAnimation(0.5s);
        }
        &:nth-child(4) {
          @include navItemAnimation(0.6s);
        }
        &:nth-child(5) {
          @include navItemAnimation(0.7s);
        }
        &:nth-child(6) {
          @include navItemAnimation(0.8s);
        }
      }
    }

    #nav-icon {
      flex: 1;
      display: none;
      width: 32px;
      max-width: 40px;
      height: 30px;
      padding: 2px 0;
      position: relative;
      margin: 1.5rem auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      z-index: 9;
      &.open {
        span {
          &:nth-child(1) {
            top: 10px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }

          &:nth-child(2) {
            opacity: 0;
            right: 60px;
            display: hidden;
          }

          &:nth-child(3) {
            top: 10px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 28px;
        background: #ffffff;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.35s ease-in-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2) {
          top: 8px;
        }

        &:nth-child(3) {
          top: 16px;
        }
      }

      @include respond(small) {
        display: block;
      }
    }

    .navbar-logo {
      display: flex;
      align-items: center;
      gap: 1rem;

      @include respond(small) {
        flex: 1;
      }

      img {
        width: 32px;
      }

      h1 {
        font-size: 1.5rem;
        color: #fff;
        cursor: pointer;

        @include respond(medium) {
          display: none;
        }
        @include respond(small) {
          display: block;
        }
      }
    }
  }

  .navbar-menu-item {
    color: #fff;
    font-weight: 400;
    font-size: 1.125rem;
    position: relative;
    line-height: 3;
    text-shadow: 0 0 15px #8e54e9;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 0%;
      display: block;
      height: 1px;
      transition: all 0.3s ease;
      margin-top: 2px;
      transition: all 300ms ease-in;
    }

    &::after {
      bottom: 25%;
      left: 0;
    }

    &::before {
      top: 25%;
      right: 0;
    }

    &:hover {
      cursor: pointer;

      &::after {
        width: 100%;
        height: 1px;
        bottom: 25%;
        background-color: #fff;
      }

      &::before {
        width: 100%;
        height: 1px;
        top: 25%;
        background-color: #fff;
      }
    }
  }

  .navbar-menu {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;

    @include respond(small) {
      display: none;
    }

    @include respond(medium) {
      gap: 1rem;
    }

    .navbar-menu-item-clicked {
      border-bottom: 1px solid #fff;
    }
  }

  .navbar-contact {
    display: flex;
    align-items: center;

    @include respond(small) {
      display: none;
    }

    .contact-button {
      all: unset;
      color: #fff;
      font-weight: 600;
      font-size: 1.125rem;
      text-transform: uppercase;
      padding: 0.6rem 1.8rem;
      border: 1px solid #ffffff;
      border-radius: 5px;
      @include respond(medium) {
        font-size: 1rem;
      }
      &:hover {
        background-color: #fff;
        color: #0074d1;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
