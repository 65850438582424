.admin_header {
  //   background-color: red;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .personal-section {
    .image {
      height: 40px;
      width: 40px;
      background-color: #313131;
      border-radius: 50%;
    }
  }
}
