@import "../../styles/global.scss";
.team {
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // position: relative;
  margin: 0 25px;

  @include respond(medium) {
    width: 100%;
    gap: 4.5rem;
  }
  @include respond(small) {
    width: 70%;
    gap:5.5rem;
  }

  &-image {
    display: flex;
    justify-content: center;
    // border: 1px solid red;
    @include respond (medium){
      // border: 1px solid lightskyblue;
      width: 100%;
    }
    @include respond(small){
      // border: 1px solid lightskyblue;
      width: 100%;
  
    }
    .sliderimg{
      // border: 1px solid green;
      @include respond(medium){
        width: 100%;
      }
      @include respond (small){
        width: 100%;
      }

    }
  }
  &-detail {
    position: relative;
    top: -110px;
    align-self: center;
    width: 70%;
    margin: auto;
    padding: auto;
    // @include respond
    @include respond(small){
      // background-color: red;

    }
  }

  &-name {
    // border: 1px solid green;
    font-family: Mazzard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #2ea3f2;
  }
  &-role {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #ff561d;
  }
}
