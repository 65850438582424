@import './../../styles/variables.scss';
@import "../../styles/global.scss";


@function toRem($value) {
    $remValue: ($value / 16)+rem;
    @return $remValue;
}

.about_us_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 6.25rem;

    // border: 1px solid red;
    @include respond(medium){
        margin-top: 2.5rem;
    }
    @include respond(small) {
        margin-top: 3rem;
    }

    .about_content {
        // border: 1px solid blue;
        @include respond(medium){
            margin-top: 5%;
        }
        @include respond(small){
            margin-top: 12%
        }

        .title_about {
            @include largefont;
            text-transform: capitalize;
            // border: 1px solid green;
            margin: toRem calc(5) auto;
        }
    }

    .card_container {
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 2% 10%;
        // border: 1px solid red;
        @include respond (medium){
            margin:2% 5%;
            // flex-wrap: nowrap;
        }

        .card_about {
            // border: 1px solid green;
            width: 326px;
            height: 203px;
            margin: 2.2%;
            border-radius: 16px;
            box-shadow: 0px 4px 30px 6px rgba(191, 211, 225, 0.3);
            @include respond (medium){
                width: 300px;
            }
        }

        .card1 {
            background-image: url(../../assets/images/about1.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;


                & h3 {
                    @include heading_about_card;



                }

                .orange {
                    @include orange_text;


                }

                .ltext {
                    @include large_text;
                }
            }
        }

        .card2 {
            background-image: url(../../assets/images/about2.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;

                & h3 {
                    @include heading_about_card;

                    @include respond(small) {
                        margin-top: 1.3rem;
                    }

                }

                .orange {
                    @include orange_text;


                }

                .ltext {
                    @include large_text;
                }
            }
        }

        .card3 {
            background-image: url(../../assets/images/about3.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;

                & h3 {
                    @include heading_about_card;

                }

                .orange {
                    @include orange_text;


                }

                .ltext {
                    @include large_text;
                }
            }
        }

        .card4 {
            background-image: url(../../assets/images/about4.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;

                & h3 {
                    @include heading_about_card;

                    @include respond (small) {
                        font-size: 2.3rem;
                    }

                }

                .orange {
                    @include orange_text;


                }

                .ltext {
                    @include large_text;
                }
            }
        }

        .card5 {
            background-image: url(../../assets/images/about5.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;

                & h3 {
                    @include heading_about_card;

                    @include respond(small) {
                        font-size: 2.3rem;
                    }

                }
            }

            .logo_wrapper {
                // border: 1px solid red;
                margin-top: 30px;
                margin-left: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-right: 40px;

                @include respond(small) {
                    // justify-content: flex-start;
                }

                .card_logo {
                    // border: 1px solid green;
                    margin: 5px;

                    @include respond(small) {
                        margin: 3px;
                        margin-bottom: 10px;
                    }
                }

            }
        }

        .card6 {
            background-image: url(../../assets/images/about6.svg);
            background-position: right;
            background-repeat: no-repeat;
            background-position-y: bottom;

            .text_content {
                // border: 2px solid red;
                @include text_content;

                & h3 {
                    @include heading_about_card;

                    @include respond (small) {
                        font-size: 2.3rem;
                    }

                }

                .orange {
                    @include orange_text;
                }

                .card_para {
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }

    }
}

.about_mainsection {
    margin-top: 30px;
    // border: 2px solid red;
    // padding: 0 100px;

    .section_first {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto 10%;
        gap: 1.5rem;
        .mainsection_image {
            // border: 1px solid red;
            margin-right: 10px;

            & img {
                @include respond (small) {
                    width: 80%;
                }
                @include respond(medium){
                    // width: 80%;
                }
            }
        }

        .mainsection_aboutcontainer {
            // border: 1px solid green;
            // margin-right: 80px;

            .title_abtcompany {
                font-weight: 600;
                font-size: 17px;
                line-height: 21px;
                align-items: center;

                /* Background_color */

                color: #0C71C3;
                margin: 15px;
            }

            .about_subheading {
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                text-transform: capitalize;
                color: #444445;
                margin: 0px auto 30px 15px;
            }

            .para {
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
                color: #444445;
                margin: 0px auto 30px 15px;
                text-align: justify;
            }
        }
    }

}
.first{
    @include respond(small) {
        flex-direction: column;
        justify-content: center;
    }
    @include respond(medium) {
        flex-direction: column;
        justify-content: center;
    }

}
.second {

    @include respond(small) {
        flex-direction: column-reverse;
        // border: 1px solid red;
    }
    @include respond(medium) {
        flex-direction: column-reverse;
        // border: 1px solid red;
    }

}