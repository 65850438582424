@import "../../styles/global.scss";
.service {
  // flex-grow: 1;
  width: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: 0px 8px 52.2889px rgba(68, 68, 68, 0.1),
    0px 4px 30.6229px rgba(68, 68, 68, 0.0759259),
    0px 4px 16.655px rgba(68, 68, 68, 0.0607407),
    0px 2px 8.49694px rgba(68, 68, 68, 0.05),
    0px 2.33px 4.26057px rgba(68, 68, 68, 0.0392593),
    0px 1.21039px 2.05766px rgba(68, 68, 68, 0.0240741);
  border-radius: 10px;
  padding: 2rem;
  min-height: 240px;
  // border: 1px solid red;
  @include respond(medium) {
    width: 40%;
  }
  @include respond(small) {
    width: 100%;
  }
  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2ea3f2;
    width: 88px;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &-name {
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      color: #444445;
    }
  }

  &-info {
    p {
      margin: 0;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      background-color: rgba(68, 68, 69, 1);
      color: transparent;
      background-clip: text;
      color: #444445;
    }
  }
}
