@import "../../styles/global.scss";

.getinwrapper {
    padding: 5% 10%;
    // border: 1px solid green;

    .getin_head {
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 5%;

        @include respond(medium) {
            width: 85%;
            margin: 3rem auto;

        }
        @include respond (small){
            width: 100%;
            margin-top: 5rem;
            // margin: auto;
        }

        h1 {
            font-size: 3rem;
            font-weight: 500;
            line-height: 3.8rem;
            margin-bottom: 1.5%;

            @include respond(medium) {
                font-size: 2rem;
            }

        }

        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
        }
    }

    .getin_body_container {
        display: flex;
        justify-content: center;
        margin-top: 8%;
        // border: 1px solid blue;

        @include respond(medium) {
            width: 80%;
            margin: auto;
        }
        @include respond(small){
            flex-direction: column;
            width: 100%;
        }

        .g_card {
            // border: 1px solid red;
            display: flex;
            // justify-content: center;
            flex-direction: column;
            align-items: center;
            // width: 25%;
            padding: 2% 3%;

            .img_container {
                // border: 1px solid red;
                // padding: 10%;
                border-radius: 50%;
                display: inline-block;
                width: 80px;
                height: 80px;
                margin-bottom: 1rem;

                @include respond (medium) {
                    width: 60px;
                    height: 60px;
                    margin-bottom: .5rem;
                }


                img {
                    width: 35%;
                    height: 35%;
                    // border: 1px solid red;
                    margin: 32.5%;
                }

            }

            h3 {
                align-items: center;
                font-size: 1.5rem;
                font-weight: 400;
                color: #595B62;
                line-height: 1.8rem;
                margin-bottom: 1.375rem;

                @include respond(medium) {
                    font-size: 1.3rem;
                    margin-bottom: .4rem;
                }
            }

            h5 {
                text-align: center;
                // border: 1px solid green;
                font-size: 1.1rem;
                font-weight: 400;
                color: #595B62;
                line-height: 1.3rem;
                margin-bottom: 1.375rem;

                @include respond (medium) {
                    font-size: .9rem;
                    margin-bottom: .3rem;
                }
            }

            #first {
                background-color: #FFE3DF;
            }

            #second {
                background-color: #D4F8E7;
            }

            #third {
                background-color: #E5F1FF;
            }
        }
    }
}

.form_section {
    background-color: rgba(12, 113, 195, 0.1);
    padding: 10% auto 10% auto;
    border: 1px solid transparent;

    .form_container {
        display: flex;
        justify-content: space-evenly;
        // border: 1px solid red;
        margin: 7%;
        background-color: #fff;
        border-radius: 15px;
    }

    .col-1 {

        // border: 1px solid blue;
        @include respond (small){
            display: none;
        }
        .col_1_wrapper {
            h1 {
                font-weight: 400;
                font-size: 2.5rem;
                line-height: 4rem;
                margin: 3% 15%;

                @include respond (medium) {
                    font-size: 2rem;
                }
            }

            img {
                // border: 1px solid red;

                @include respond (medium) {
                    // border: 1px solid blue;
                    width: 80%;
                    margin-left: 10%;
                }
            }


        }
    }

    .col-2 {

        // border: 1px solid green;
        .col_2_wrapper {
            // border: 1px solid green;
            margin: 3% 10%;

            h2 {
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2.1rem;
                margin: 3% 15%;
                @include respond (medium){
                    font-size: 1.2rem;
                    margin: 2% 5%;
                }
            }

            p {
                text-align: center;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #0C71C3;
                @include respond (medium){
                    font-size: .9rem;
                }
            }
        }
    }
}