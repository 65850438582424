@import "../../../styles/global.scss";

.dsection_top {
  display: flex;
  justify-content: center;
  width: 80%;
  @include respond(medium) {
    flex-direction: column;
  }
  @include respond(small) {
    flex-direction: column;
  }

  // border: 1px solid red;
  margin: auto;
  .dsection_left {
    flex-basis: 50%;
    img {
      @include respond(small) {
        width: 98%;
      }
    }
    // border:1px solid red;
  }
  .dsection_right {
    flex-basis: 50%;
    padding-left: 5%;
    h1 {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
      @include respond(small) {
        font-size: 28px;
      }
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 25px;
      @include respond(small) {
        margin-top: 15px;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      .icon {
        margin: 36px 16px;
        // border: 1px solid red;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 100px;
        text-transform: capitalize;
        @include respond(small) {
          margin-right: auto;
        }
      }
    }
  }
}
.dsection_button {
  @include respond(medium) {
    flex-direction: column-reverse;
  }
  @include respond(small) {
    flex-direction: column-reverse;
  }
}
.dsection_image_buttom {
  width: 550px;
  // border: 1px solid blue;
  margin-left: 50px;
  @include respond(small) {
    margin-left: auto;
  }
}

.blist {
  display: flex;
  justify-content: flex-start;
  margin: 50px auto;
  .icon {
    margin: 10px 16px;
    // border: 1px solid red;
  }
  .blist_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 80%;
    // border: 1px solid red;
    h3 {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      // border: 1px solid blue;
    }
    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: capitalize;
      // border: 1px solid blue;
      margin-top: auto;
    }
  }
}
