@import "../../../styles/global.scss";

.section_top {
  display: flex;
  justify-content: center;
  //   border: 1px solid red;
  .section_left {
    flex-basis: 40%;
  }
  .section_right {
    flex-basis: 60%;
    padding-left: 5%;
    h1 {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 25px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .icon {
        margin: 36px 16px;
        // border: 1px solid red;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 100px;
        text-transform: capitalize;
      }
    }
  }
}
.title {
  width: 68%;
  margin: auto;
  h1{
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  @include respond (small){
    font-size: 28px;
    line-height: 36px;
  }
  }
  .bar{
    width: 40px;
    height: 2px;
    background-color: #ff561d;
    margin-top: 8px;
    margin-bottom: 30px;
  }
}
.scardwrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 900px;
  max-width: 100%;
  margin: auto auto 50px auto;
  gap: 20px;
}

// style for cards
.scard_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 425px;
  border-top: 10px solid #0c71c3;
  border-left: 1px solid #444445;
  border-right: 1px solid #444445;
  border-bottom: 1px solid #444445;
  // margin: 10px;
  padding: 20px;
  max-width: 90%;
  h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;

  }
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
}

// additional style for second class
.pro_title{
  margin: 10px auto;

}
.service_product_list{
  display: flex;
  justify-content: flex-start;
  // border: 1px solid green;
  .bullets{
    // border: 1px solid red;
    svg{
      margin: 12px  10px 0px 15px;
      // padding: 0%;
    }

  }
  p{
    // border: 1px solid red;
    margin:5px;
    padding: 0%;
  }
}