@import "../../styles/global.scss";


.test {
    background-color: greenyellow;
    height: 40vh;
}

/*
rem conversion
 */
@function toRem($value) {
    $remValue: calc($value/16)+rem;
    @return $remValue;
}




@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

$bgcolor1: #1E1E1E;
$bgcolor2: #0C71C31A;
$primary:#2EA3F2;


/*
styling for section first started from here
..............................................
*/


//main-section is the whole div for this section.

//main-section-container contain everything of this section which position is at the center of main div.
.main-section-container {
    margin: 5rem 8.5rem 5rem 8.5rem;
    // height: toRem(518);
    // width: toRem(1170);
    // border: 1px solid red;
    @include flex-center();
    @include respond (medium){
        margin-top: 10%;
    }
    @include respond(small){
        flex-direction: column;
        margin: 8rem 0rem 3rem 0rem; 
    }
    

}

// this is the  left part of section which is a image.
.img {
    // border: 1px solid green;
    margin-left: 1.5rem;
    @include respond(medium){
        width:50%;
    }
    @include respond (small){
        width: 90%;
        margin-left: 0%;
    }
    
}

//this is the right-part of section which contain title of section and descrptions
// further this section will also divided on three divison.
.section-right-container {
    position: relative;
    // height: toRem(503);
    width: toRem(555);
    // background-color: purple;
    // border: 1px solid red;
    @include respond (small){
        margin-top: 1rem;
        height: 50%;        
    }

}

/*this is first division of  right section which is on top;
it content title of section and  small horizantal line.
*/
.title-container {
    // border: 1px solid purple;
    display: inline;
    @include respond (small){
        text-align: center;
        display: block;
    }
}

// this is title 
.title {
    // color:purple;
    font-size: toRem(16);
    line-height: toRem(23);
    font-weight: 500;
    position: relative;
    text-transform: uppercase;

    // horizantal line just after the title.
    &::after {
        content: '';
        height: 2px;
        width: toRem(40);
        background: linear-gradient(90deg, #833AB4 -9.08%, #FF561D 20.78%, #FD1D1D 83.14%, #FCB045 103.48%);
        position: absolute;
        right: -80%;
        top: 50%;
    }
}

//this is the main portion of this section is the  container the decription.
.body-container {
    // border: 1px solid blue;
    height: toRem(259);
    width: toRem(592);
    position: relative;
    @include respond (medium){
        width:100%;
        // position: relative;
        height: 8.3rem;
    }
    @include respond (small){
        width: 100%;
        height: 6rem;
    }

    //this is content of main portion.
    .body-content {
        // font-family: 'playfair Display';
        position: absolute;
        height: toRem(259);
        left: 0%;
        right: -6.67%;
        top: 0px;
        font-weight: 500;
        font-size: 2.25rem;
        line-height: toRem(46);
        text-transform: capitalize;

        @include respond (medium){
            font-size: 1.25rem;
            line-height: 1.6rem;
            display: block;
            right: 2%;
            text-align: justify;
            position: relative;
            align-self: center;
            // border: 1px solid blue;
            // height: 50%;
        }
        @include respond (small){
            font-size: 1.25rem;
            line-height: 2rem;
            display: block;
            right: 2%;
            text-align: justify;
            position: relative;
            
        }

    }

    //the highlight portion of the section
    .highlight {
        background-clip: text;
        color: transparent;
        background-image: linear-gradient(90deg, #833AB4 -9.08%, #FF561D 20.78%, #FD1D1D 83.14%, #FCB045 103.48%),
            linear-gradient(0deg, #363636, #363636);
        @include respond (small){
            display: inline;
            font-size: 1.25rem;
            line-height: 2rem;
        }
        @include respond (medium){
            display: inline;
            font-size: 1.25rem;
            line-height: 2rem;
            // border: 1px solid purple;
        }

    }


}

// this containe= some more minor description.
.subbody-content {
    // font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(28);
    @include respond (medium){
        font-size: .8rem;
        text-align: justify;
        margin-top: .5rem;
        // border: 1px solid red;
    }
    @include respond (small){
        text-align: justify;
        margin-top: .5rem;
        font-size: 1rem;
    }

}

/*
main section started
*/

/*

second section starts here.
*/

.vacancy-section {
    // height: toRem(895);
    // width: toRem(1440);
    background-color: $bgcolor2;
    @include flex-center();
    flex-direction: column;
    // border: 1px solid red;
    // justify-content: flex-start;
    padding-bottom: 3rem;

}

.join-us {
    margin-top: 5rem;
    // border: 1px solid red;
    @include respond(small){
        margin-top: 2rem;
    }
}

.join-us-content {
    text-transform: uppercase;
    font-size: toRem(16);
    line-height: toRem(23);
    font-weight: 500;
    position: relative;

    &::after {
        content: '';
        height: 2px;
        width: toRem(40);
        background: linear-gradient(90deg, #833AB4 -9.08%, #FF561D 20.78%, #FD1D1D 83.14%, #FCB045 103.48%);
        ;
        position: absolute;
        right: -60%;
        top: 50%;
    }

    &::before {
        content: '';
        height: 2px;
        width: toRem(40);
        background: linear-gradient(90deg, #833AB4 -9.08%, #FF561D 20.78%, #FD1D1D 83.14%, #FCB045 103.48%);
        ;
        position: absolute;
        left: -60%;
        top: 50%;
    }

}

.vacan-heading-container {

    .vacan-head {
        // font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 2.8rem;
        text-transform: capitalize;
        @include respond(small){
            font-size: 2rem;
        }
    }

    .head-hglt {
        background-image: linear-gradient(90deg, #833AB4 -9.08%, #FF561D 20.78%, #FD1D1D 83.14%, #FCB045 103.48%),
            linear-gradient(0deg, #363636, #363636);
        background-clip: text;
        color: transparent;
        @include respond(small){
            font-size: 2rem;
        }
    }
}

.card-wrapper {
    display: flex;
    // justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 90%;
    // border: 1px solid red;
    @include respond (medium){
        margin: 1rem;
    }
    @include respond(small){
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }
}

.Card_container {
    // border: 2px solid grey;
    display: inline-block;
    height: 16rem;
    width: 380px;
    margin: 18px;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 13px;
    @include respond(medium){
        width: 24rem;
    }
    @include respond (small){
        width: 95%;
    }

    .vacan_position {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 2rem;

    }

    .func_title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .card_info {
        // font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.8rem;
        color: #525252;
        @include respond (small){
            font-size: 1.3rem;
            line-height: 2rem;
        }
    }
    a{
        text-decoration: none;
        color: black;
        text-transform: capitalize;
        margin-top: 3px;
        line-height: 1.8rem;
    }
}
.Card_container{
    border-left: 3px solid transparent;
    &:hover {
        .vacan_position{
            color: $primary;
        }
        border-left: 3px solid $primary;
    }
}