@import '../../styles/global.scss';
.project {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 2rem 0;
    width: 20%;
    @include respond(medium) {
        width: 40%;
    }
    @include respond(small) {
        padding: 1rem 0;
    }
    div {
        &:first-child {
            width: 70px;
            aspect-ratio: 1;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-name {
        h2 {
            font-size: 1.5rem;
            font-weight: 400;
            color: #0C71C3;
        }
    }
    &-number {
        color: #0C71C3;
        h2 {
            font-size: 1.25rem;
        }
    }

    .pink {
        background-color: #B68AFB;
        box-shadow: 0px 4px 30px 10px rgba(182, 138, 251, 0.1);
    }

    .red {
        background: #F83535;
        box-shadow: 0px 4px 30px 10px rgba(248, 53, 53, 0.1);
    }

    .orange {
        background: #FCB045;
        box-shadow: 0px 4px 30px 10px rgba(252, 176, 69, 0.1);
    }

    .lightGreen {
        background: #90EE90;
        box-shadow: 0px 4px 30px 10px rgba(255, 86, 29, 0.1);
    }
}