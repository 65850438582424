@import "../../../styles/global.scss";

.section_top {
  display: flex;
  justify-content: center;
  @include respond(medium){
    flex-direction: column;
  }
  @include respond (small){
    flex-direction: column;
  }
  //   border: 1px solid red;
  .section_left {
    flex-basis: 40%;
    img{
    @include respond(small){
      width: 98%;
    }
  }
  }
  .section_right {
    flex-basis: 60%;
    padding-left: 5%;
    h1 {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 25px;
      
    }
    .list {
      display: flex;
      justify-content: space-between;
      .icon {
        margin: 36px 16px;
        // border: 1px solid red;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 100px;
        text-transform: capitalize;
        @include respond (small){
          margin-right: auto;
          // border: 1px solid red;
        }
      }
    }
  }
}

.card_wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: auto auto 100px auto;
  @include respond(medium){
    // border: 1px solid red;
    flex-wrap: wrap;
  }
}
.card_container_app {
  // border: 1px solid red;
  width: 325px;
  background-color: #f4f5f4;
  @include respond(medium){
    margin-top: 10px;
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .img {
      // object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
  h1 {
    font-family: Barlow Condensed;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 60px 10px 48px;
    color: #363636;
    @include respond(small){
      margin: 5%;
    }
  }
  p {
    margin:10px 53px 75px 48px ;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    @include respond(small){
      margin:5%;
    }
  }
}
