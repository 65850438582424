@import "../../../styles/global.scss";

.gsection {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 90%;
  margin: auto auto 75px auto;
  gap: 5%;
  @include respond(medium) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
  }
  @include respond(small) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .gleft {
    // border: 1px solid red;
    flex-basis: 50%;
    img {
      // border: 1px solid blue;
      width: 112%;
      margin-top: 8%;
      @include respond(medium) {
        width: 100%;
        margin: auto;
      }
      @include respond(small) {
        width: 100%;
        margin: auto;
      }
    }
  }
  .gright {
    // border:1px solid green;
    flex-basis: 50%;
    @include respond(medium) {
      flex-basis: none;
      margin-left: 20px;
      padding: auto;
      margin-right: auto;
      padding-right: 0px;
    }
    @include respond(small) {
      flex-basis: auto;
      margin-left: 20px;
    }
    h1 {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      text-align: left;
      color: #363636;
      margin: 50px 85px 25px auto;
      @include respond(medium) {
        margin: auto;
        width: 100%;
        // border: 1px solid red;
        text-align: center;
      }
      @include respond(small) {
        margin: auto;
      }
    }
    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #444445;
      margin-right: 15%;
      margin-bottom: 35px;
      @include respond(medium) {
        margin: auto;
        width: 100%;
        // border: 1px solid red;
        // text-align: center;
      }
      @include respond(small) {
        margin-right: 10px;
        text-align: justify;
      }
    }
    .card_contain {
      display: flex;
      flex-wrap: wrap;
      @include respond(medium) {
        justify-content: space-evenly;
        margin-top: 20px;
      }
    }
  }
}

// style of card

// style of card

.gcard_container {
  // border: 1px solid red;
  margin: 5px 10px 5px 0px;
  display: flex;
  padding: 5px;
  width: 275px;
  background: #ffffff;
  box-shadow: 0px 4px 16.655px rgba(68, 68, 68, 0.0607407),
    0px 2px 8.49694px rgba(68, 68, 68, 0.05),
    0px 2.33px 4.26057px rgba(68, 68, 68, 0.0392593),
    0px 1.21039px 2.05766px rgba(68, 68, 68, 0.0240741);
  border-radius: 10px;
  @include respond(medium) {
    width: 300px;
  }
  @include respond(small) {
    width: 325px;
  }
  svg {
    margin-top: 25px;
    margin-right: 10px;
    margin-left: 15px;
    @include respond(medium) {
      margin: 10px;
    }
  }
  .gcardp {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #444445;
    margin: 15px auto auto 5px;
  }
}
