@mixin largefont {
    font-size: 48px;
    font-weight: 500;
    line-height: 59px;
    letter-spacing: 0em;
}

/*
this is used for styling about us page. this is mainly used on card of about
page to sylte the text inside the card */

@mixin text_content {
    margin: 15px auto auto 15px;
    padding-right: 50px;
}

@mixin heading_about_card {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.8rem;
    @include respond (small){
        font-size: 3rem;
    }
}
$gradient-blue: linear-gradient(268.49deg, #0C71C3 9.81%, #2EA3F2 91.82%);
@mixin orange_text {
    color: #FF561D;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
}
$gradient-blue: linear-gradient(268.49deg, #0C71C3 9.81%, #2EA3F2 91.82%);
@mixin large_text {
    font-weight: 400;
    font-size: 48px;
    line-height: 58.51px;
    display: inline;
}