@import "../../../styles/global.scss";

.service_nav_wrapper{
    // border-bottom:3px solid #FFF6F9;
    margin: 40px auto -3px auto;
    width: 80%;
    overflow-x :scroll;
    &::-webkit-scrollbar {
        width: 0px;
      }

    .service_nav{
        display: flex;
        justify-content: space-between;
        padding:9px 20px 0px 20px;
        .services_nav_item{
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #363636;
            cursor: pointer;
            border-bottom: 3px solid transparent;
            padding: 0px 8px;
            padding-bottom: 10px;
            white-space: nowrap;


        }
        .active{
            border-bottom:3px solid #FF3838;
        }
    }
}
.space{
    height: 40px;
    width: 80%;
    margin: auto;
    border-top:3px solid #fff6f9;
} 