@import "../../styles/global.scss";

.contact-wrapper {
    background: linear-gradient(267.39deg, #0C71C3 17.05%, #0680E3 65.13%, #2EA3F2 97.32%);

    .contact_wrapper_child {
        background-image: url(../../assets/images/contact_1.png);
        background-repeat: no-repeat;
        background-position: top;
        background-position-x: left;
        // border: 1px solid red;
        padding: 5% 5% 0% 5%;
        // margin: 20%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        // align-items: center;
        .contact_decrp {
            // border: 1px solid blue;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @include respond(medium){
                margin-top: 5%;
            }
            @include respond(small){
                margin-top: 15%;
            }

            h1 {
                color: #DFEBFF;
                margin-top: 2%;
                font-size: 3rem;
                font-weight: 300;
                line-height: 3.5rem;

                @include respond (medium) {
                    font-size: 2.5rem;
                }
                @include respond (small){
                    font-size: 1.5rem;
                }

                span {
                    font-weight: 500;
                    font-size: 3rem;
                    line-height: 3.5rem;

                    @include respond (medium) {
                        font-size: 2.5rem;
                    }
                    @include respond(small){
                        font-size: 1.5rem;
                    }
                }
            }

            p {
                color: #DFEBFF;
                font-size: 1rem;
                font-weight: 400;
                margin-top: 1%;
                @include respond(small){
                    text-align: center;
                }
            }
        }

        .contact_form_wrapper {
            border-radius: 5%;
            border-radius: 25px;
            width: 70%;
            overflow: hidden;
            margin: 4% auto -3% auto;
            display: flex;
            justify-content: center;
            @include respond(small){
                width: 100%;
            }



            .img_container {
                background-image: url(../../assets/images/contact_2.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-size: cover;
                width: 40%;
                
                &-text{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    // background-color: red;
                    margin: 45px auto auto 32px;
                }
                @include respond(medium){
                    display: none;
                }
                @include respond(small) {
                    display: none;
                }

                // border-radius : 5%;
                h3 {
                    // margin: 15% 10% auto 10%;
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: #DFEBFF;
                    // border: 1px solid blue;
                    // margin-bottom: 15px;

                    @include respond(medium) {
                        font-size: 1.25rem;
                        margin: 12% 8% auto 8%;
                    }
                    @include respond(smalllaptop){
                        font-size: 1.25rem;
                        margin: 12% 8% auto 8%;
                    }



                }

                h5 {
                    // margin: 10% 7% auto 10%;
                    font-weight: 400;
                    font-size: 1.25rem;
                    color: #DFEBFF;
                    // border: 1px solid blue;

                    @include respond(medium) {
                        font-size: 1rem;
                        margin: 8% 8% auto 8%;
                    }
                    @include respond(smalllaptop){
                        font-size: 1rem;
                        margin: 8% 8% auto 8%;
                    }
                }


            }

            .form_container {
                background-color: #fff;
                width: 60%;
                @include  respond (medium){
                    width: 100%;
                }
                @include respond(small) {
                    // border: 1px solid red;
                    width: 100%;
                }

                .form_wrapper {
                    margin: 10% 7% 10% 5%;

                    // border: 1px solid green;
                    h4 {
                        font-size: 1.25rem;
                        font-weight: 400;
                        @include respond(small){
                            font-size: 1.5rem;
                        }
                    }
                }

                .contact_handle {
                    display: flex;
                    justify-content: space-around;
                    margin: 7% auto 4% auto;
                    @include respond(medium){
                        justify-content: center;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    
                    .contact_icon {
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                        align-items: center;
                        @include respond(medium){
                            // border: 1px solid red;
                            flex-direction: row;
                        }

                        // border: 1px solid blue;
                        svg {
                            width: 45px;

                            @include respond(medium) {
                                width: 14px;
                                // border: 1px solid red;
                            }
                            @include respond(small){
                                width: 15;
                            }
                        }

                        p {
                            font-size: .80rem;
                            font-weight: 400;
                            margin: 1.5rem auto;

                            @include respond(medium) {
                                font-size: .7rem;
                                margin:.4rem;
                            }
                            @include respond (small){
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}


// this is the style for form component. 
.form {

    // border: 1px solid red;
    // padding-right: 20%;
    .c_form {
        display: flex;
        justify-content: center;
        flex-direction: column;

        // border: 1px solid blue;
        .form_inputs {
            // background-color: green;
            padding: 2.5%;
            // margin: 3% 0%;
            font-size: 14px;
            font-weight: 400;
            width: 90%;
            border: none;

            &::placeholder {
                background-color: transparent;
                font-size: 1rem;
                font-weight: 400;
                // padding: 1%;
            }

            &:focus {
                // border: 1px solid black;
                // background-color: red;
                outline: none;
            }
           
        }

        .form_text {
            // border: 1px solid red;
            padding-bottom: 10%;
            padding-top: 10px;

        }
        .form_optn_show{
            font-size: 1rem;
            font-weight: 300;
            opacity: 55%;
            
        }

        button {
            background-color: #2EA3F2;
            font-size: 1.1rem;
            font-weight: 700;
            color: #DFEBFF;
            padding: 2.3%;
            border: 3px solid transparent;
            border-radius: 5px;
            margin: 2%;
            &:hover{
                border: 3px solid #0c71c3;
                background-color: #0c71c3;
                color: #ffffff;
            }
        }
    }

}

.input_container {
    border: 1px solid #C2C7D0;
    border-radius: 5px;
    margin: 2%;
    display: flex;
    align-items: flex-start;
    width: 96%;
    // padding: 12px auto auto 15px;
    

    .form_icons {
        // border: 1px solid blue;
        // margin-left: 1%;
        color:#444445;
        font-size: 24px;
        margin-left: 15px;
        padding-top: 12px;
        @include respond (medium){
            font-size: 18px;
        }
        @include respond (small){
            width: 15px;
            padding-top: 10px;
            // background-color: red;
        }
    }
    .littledim{
        opacity: 80%;
    }

    .text_icons {
        // border: 1px solid greenyellow;
        align-self: flex-start;
        margin-top: 1.5%;
    }
    

}
.contact-footer {
    .footer-section {
        padding-top: 3rem;
    }
}
