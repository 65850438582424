@import "../../styles/global.scss";
.testimonial-container {
  position: relative;
  padding: 4rem 6rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  &-image {
    img {
      margin: 0 auto;
      width: 100px;
      aspect-ratio: 1;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-info {
    text-align: center;

    h2 {
      font-size: 1.875rem;
      font-weight: 500;
      margin-bottom: 6px;
    }

    p {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: transparent;
      background: rgba(149, 152, 160, 1);
      background-clip: text;
    }
  }

  &-main {
    text-align: center;
    display: flex;
    justify-content: center;
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.7rem;
      color: transparent;
      background: rgba(68, 68, 68, 1);
      background-clip: text;
      width: 80%;
      text-align: center;
      @include respond(medium) {
        width: 100%;
      }
    }
  }

  .testimonial-index {
    position: absolute;
    top: 100;
    left: 50px;
    font-size: 7rem;
    color: rgba(149, 152, 160, 0.1);
  }
}

.arrows-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  //   border: 1px solid red;

  bottom: -5rem;
  left: 50%;
  transform: translateX(-50%);
  button {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0.7rem 0.8rem;
    cursor: pointer;
    img {
      width: 24px;
    }
    &:hover {
      background-color: rgba(149, 152, 160, 0.3);
    }
  }
}
.slider {
  box-shadow: 0px 20px 60px rgba(46, 33, 61, 0.08);
}
